
import { Box } from "@worthy-npm/worthy-common-ui-components";
import { useEffect, useState } from "react";
import useUINotification from "src/hooks/useUINotification";
import { useAppSelector } from "src/store";
import {  getNotifications } from "src/store/notificationsSlice";


const UINotification: React.FC = () => {
    const notifications = useAppSelector(getNotifications);
    const { showNotification } = useUINotification();
    const [displayedNotificationIds, setDisplayedNotificationIds] = useState<Set<string>>(new Set());

    useEffect(() => {
        const unreadNotifications = notifications.filter(notification => !notification.markAsReadAt);
        const sortedNotifications = unreadNotifications.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        const limitedNotifications = sortedNotifications.slice(0, 3);
        const newNotifications = limitedNotifications.filter(notification => !displayedNotificationIds.has(notification.id));
        newNotifications.forEach((notification) => {
            showNotification(notification);
            setDisplayedNotificationIds(prev => new Set(prev).add(notification.id));
          });
        }, [notifications,displayedNotificationIds,showNotification]);
  return (
      <Box />
  );
};

export default UINotification;
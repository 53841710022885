import { Box,Icon,IconButton, SnackbarContent, Stack, styled, Typography } from "@worthy-npm/worthy-common-ui-components";

import  LocalIcons from '../Icons';
import { forwardRef } from "react";
import { useAppDispatch } from "src/store";
import { markNotificationAsRead } from "src/store/notificationsSlice";

const StyledSnackbar = styled(SnackbarContent)(({theme})=>({
        backgroundColor: theme.palette.tertiary.main,
        padding: theme.spacing(2),
        color: theme.palette.common.white,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1.5),
            textAlign: 'center',
        },
        '& .MuiSnackbarContent-message': {
            padding: 0,
            width: '100%',
        }
}));

const StyledCloseContainer = styled(Box)(({theme})=>({
    position: 'absolute',
    right: theme.spacing(-1),
    top: theme.spacing(-1),
    [theme.breakpoints.down('sm')]: {
        right: theme.spacing(-0.5),
        top: theme.spacing(-0.5),
    },
}));


export interface SuccessNotificationProps {
  id: string;
  title?:string;
  message?: string;
  offline?: boolean;
  onClose: (key: string) => void;
}

const SuccessNotification = forwardRef<HTMLDivElement, SuccessNotificationProps>(({ id, title, message, onClose, offline }, ref) => {
  const dispatch = useAppDispatch();

const handleClose = () => {
    onClose(id);
    if(offline){
        return;
    }
    void dispatch(markNotificationAsRead(`${id}`));
}

return (
      <StyledSnackbar
          ref={ref}
          id={id}
          message={(
            <Stack  position={'relative'} gap={{sm: 2}} direction={'row'} alignItems={'start'} color={'common.white'} justifyContent={'space-between'} >
                <Stack width={'100%'} gap={2} direction={{sm:'row'}} alignItems={'center'}>
                    <Typography fontSize={{xs: '1.3rem',sm: '1.8rem'}}>
                        <LocalIcons.WorthyShineCheckIcon  color="inherit" fontSize='inherit' />
                    </Typography>
                    <Stack gap={{xs: 1.5, sm: 1}}>
                        {title && <Typography variant="h3" mr={{sm: 3}}>{title}</Typography>}
                        {message && <Typography variant="body1">{message}</Typography>}
                    </Stack>
                </Stack>
                <StyledCloseContainer >
                    <IconButton color="inherit" size={'small'} onClick={handleClose} edge="end" >
                        <Icon.CloseIcon color="inherit" />
                    </IconButton>
                </StyledCloseContainer>
            </Stack>
          )}
      />
);
});




SuccessNotification.displayName = 'SuccessNotification';

export default SuccessNotification;
import { PropsWithChildren } from 'react';
import Header from 'src/layout/nav/header.tsx';
import { Box, Drawer } from '@worthy-npm/worthy-common-ui-components';
import Navigation from 'src/layout/nav/navigation.tsx';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getSchedulingCurrentStep, isSchedulingOpen, setOpenScheduling } from 'src/store/schedulingSlice';
import SchedulingPopUp from 'src/components/SchedulingPopUp/SchedulingPopUp';
import Banner from 'src/components/banner/Banner.tsx';

const BaseLayout = (props: PropsWithChildren) => {
    const dispatch = useAppDispatch();
    const openReschedule = useAppSelector(isSchedulingOpen);
    const currentStep = useAppSelector(getSchedulingCurrentStep);

    return (
        <Box>
            <Banner />
            <Header />
            <Navigation />
            <Box>{ props.children }</Box>
            {
                <Drawer
                    anchor="bottom"
                    open={ openReschedule }
                    onClose={ () => {
                        if (currentStep === 0) {
                            dispatch(setOpenScheduling(false));
                        }
                    } }>
                    <SchedulingPopUp isPC={ false } />
                </Drawer>
            }
        </Box>
    );
};

export default BaseLayout;

import { useState } from 'react';
import {
  Typography,
  Unstable_Grid2 as Grid,
  Stack,
  AccordionSummary,
  AccordionDetails,
  Button,
  Icon, Box,
} from '@worthy-npm/worthy-common-ui-components';
import ItemPhoto from 'src/components/ItemPhoto/ItemPhoto';
import {  AlertCaption, StyledAccordion, ValidItemCheckbox } from './ItemCardDetails.styles';
import _ from 'lodash';
import { pendingSchedulingStates, validSchedulingStates } from 'src/constants/item.constants';
import { pendingReason } from 'src/constants/itemCard.constants';
import { Item } from 'src/types/item.ts';
import CertificateModal from 'src/components/CertificateModal/CertificateModal.tsx';
import Ga from 'src/services/ga.service/ga.ts';
import { disablePhotoUploadState } from 'src/helpers/item';
import { useAppSelector } from 'src/store';
import { getSchedulingCurrentStep } from 'src/store/schedulingSlice';
import ClickableHelpTooltip from 'src/components/ClickableHelpTooltip/ClickableHelpTooltip.tsx';
import worthyShineTheme from '@worthy-npm/worthy-common-ui-components/theme/worthyShineTheme';


interface ItemCardDetailsProps {
  item: Item;
  onCheckboxChange?: (item: Item, isChecked: boolean) => void;
}

const ItemCardDetails = ({ item, onCheckboxChange }: ItemCardDetailsProps) => {
  const { itemDetails } = item;
  const filteredProperties = _.omit(itemDetails, ['certificate', 'certificateId', 'certificateType']);
  const isPendingState = pendingSchedulingStates.includes(item.status);
  const alertForPending = pendingReason(item.status, item.photos.length > 0);
  const currentStep = useAppSelector(getSchedulingCurrentStep);
  const enableCheckbox = validSchedulingStates.includes(item.status) && onCheckboxChange;
  const itemsWithGia = ['ring', 'loose diamond'];

  const [uploadCertificateOpen, setUploadCertificateOpen] = useState(false);

  const itemProperties = _.chain(filteredProperties)
                          .thru(obj => {
                            if ((obj.carat || obj.caratRangeTo) && item.itemDescription.includes('CT')) {
                              const beforeCT = item.itemDescription.split('CT')[0];
                              if (beforeCT.includes('-')) {
                                obj.carat = obj.caratRangeTo
                                    ? `${obj.carat || ''} - ${obj.caratRangeTo}`.trim()
                                    : obj.carat;
                              }
                            }

                            if (obj.caratClassification) {
                              obj.stoneType = obj.caratClassification;
                            }

                            return _.omit(obj, [ 'caratRangeTo', 'caratClassification' ]);
                          })
                          .value();

  const handleCertificateModalClose = () => {
    setUploadCertificateOpen(false);
  }

  const handleGiaButtonClick = () => {
    //TODO:
    Ga.SendActionNavigate({
      event: 'interaction',
      interactionType: 'success',
      location1: 'app',
      location2: 'lightListing',
      actionedObject: 'addCertificateBtn',
      actionedValue: '',
      appVersion: '',
      buyerId: undefined,
    });
    setUploadCertificateOpen(true);
  }

  const genDescription = (value: string) => {
    if (!isNaN(Number(value))) {
      return !value ? '--' : value;
    } else {
      return value.includes('-') ? value : _.startCase(value);
    }
  }

  const certificateComponent = () => {
    if (itemsWithGia.includes(item.itemType?.toLowerCase())) {
      return item?.itemDetails?.certificateId ? (
          <Typography
              variant="caption"
              fontSize={'small'}
          >
            Report #{item.itemDetails.certificateId}
          </Typography>
      ) : (
          <Stack
              direction={'row'}
              alignItems={'center'}
              gap={0.5}
          >
            <Button
                onClick={handleGiaButtonClick}
                sx={{ padding: 0 }}
            >
              <Typography
                  variant="caption"
                  fontSize={'small'}
                  color="tertiary.main"
              >
                + Upload GIA Report
              </Typography>
            </Button>
            <ClickableHelpTooltip
                title={'If you have a report from GIA (Gemological Institute of America), click below to enter your report number. If you have a report from another grading lab, upload your report as an image above.'}
            />

          </Stack>
      )
    }
  }

  return (
    <Grid
      container
      width={'100%'}
      rowGap={1.5}
    >
      {/* {alertForPending && (
        <Grid xs={12}>
          <Stack
            direction={'row'}
            gap={1}
            alignItems={'center'}
          >
            <Typography
              variant="caption"
              color={'error.main'}
            >
              {alertForPending.title}
            </Typography>
            <ClickableHelpTooltip
                title={alertForPending.description}
            />
          </Stack>
        </Grid>
      )} */}
      <Grid xs={12}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          height={'100%'}
        >
          <Stack
            justifyContent={'space-between'}
            height={'100%'}
            maxWidth="303px"
          >
            <Stack gap={{xs: 0.5, sm: 1}}>
            {alertForPending && (
        <Grid xs={12}>
          <Stack
            direction={'row'}
            gap={1}
            alignItems={'center'}
          >
            <AlertCaption
              variant="caption"
              color={'error.main'}
            >
              {alertForPending.title}
            </AlertCaption>
            <ClickableHelpTooltip
                title={alertForPending.description}
            />
          </Stack>
        </Grid>
      )}
            <Stack
              direction={'row'}
              gap={1}
            >
              {enableCheckbox && (
                <ValidItemCheckbox
                  defaultChecked
                  disableRipple
                  disabled={currentStep !== 0}
                  onChange={(e, checked: boolean) => onCheckboxChange(item, checked)}
                />
              )}
              <Typography
                variant="body1"
                fontSize={'1.1rem'}
                color={isPendingState ? 'divider' : 'text.primary'}
                pr={2}
              >
                {item.itemDescription}
              </Typography>
            </Stack>
            </Stack>
            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              color={'secondary.main'}
              flexWrap={'wrap'}
            >
              <Typography
                variant="caption"
                fontSize={'small'}
              >
                ID #{item.publicId}
              </Typography>
              {
                  itemsWithGia.includes(item.itemType?.toLowerCase()) && (
                      <Box sx={{borderRight: `1.5px solid ${worthyShineTheme.palette.action.disabled}`, height: '16px'}}/>
                  )
              }
              { certificateComponent() }
              {uploadCertificateOpen && (
                  <CertificateModal
                    open={uploadCertificateOpen}
                    onClose={ handleCertificateModalClose }
                    itemPublicId={ item.publicId }
                  />
                )}
              </Stack>
          </Stack>
          <ItemPhoto
            disableUpload={disablePhotoUploadState(item.status)}
            itemType={item.itemType?.toLowerCase()}
            itemPublicId={item.publicId}
            photosUrl={item.photos}
          />
        </Stack>
      </Grid>
      <Grid xs={12}>
        <StyledAccordion
          elevation={0}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<Icon.ExpandMoreShineIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography
              variant="body1"
              fontWeight={'600'}
              color={'secondary.main'}
            >
              Item information
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{p:0,pl:1,pb: 1}} >
            <Grid container>
              {Object.entries(itemProperties).map(([key, value]) => (
                <Grid
                  xs={12}
                  md={6}
                  key={key}
                >
                  <Stack
                    direction={'row'}
                    justifyContent={'flex-start'}
                    padding={1}
                    gap={1}
                  >
                    <Typography
                      variant="body1"
                      color={'secondary.main'}
                      fontSize={'14px'}
                      width={'104px'}
                    >
                      {_.startCase(key)}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={'500'}
                      fontSize={'14px'}
                    >

                      { genDescription(value) }
                    </Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
      </Grid>

    </Grid>
  );
};

export default ItemCardDetails;

import { createContext, useContext, useState, ReactNode, useMemo, useEffect } from 'react';
import { useAppDispatch } from "src/store";
import { fetchUserProfile } from 'src/store/userSlice';
import { Profile } from 'src/services/api/backend.service.ts';
import { fetchNotifications } from "src/store/notificationsSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { NotificationI } from "src/types/notification";

export enum AuthStatus {
    PENDING = 'PENDING',
    NOT_LOGGED = 'NOT_LOGGED',
    LOGGED = 'LOGGED',
}
interface AuthContextProps {
    user: Partial<Profile> | undefined;
    getUser: () => Promise<Partial<Profile>>;
    authenticationStatus: AuthStatus;
}

interface UserTokenProviderProps {
    children: ReactNode;
}

const AuthContext = createContext<AuthContextProps>({
    user: undefined,
    getUser: () => Promise.resolve({}),
    authenticationStatus: AuthStatus.PENDING,
});

export const AuthProvider = ({ children }: UserTokenProviderProps) => {
    const dispatch = useAppDispatch();
    const [user, setUser] = useState<Partial<Profile>>();
    const [authenticationStatus, setAuthenticationStatus] = useState<AuthStatus>(AuthStatus.PENDING);


    const getUser = async () => {
        const { payload } = (await dispatch(fetchUserProfile())) as { payload: Profile };
        if (payload) {
            setAuthenticationStatus(AuthStatus.LOGGED);
            setUser(payload);
        } else {
            setAuthenticationStatus(AuthStatus.NOT_LOGGED);
            setUser(undefined);
            return {};
        }

        return payload;
    };

    const value = useMemo(
        () => ({
            user,
            getUser,
        }),
        [user],
    );

    // TODO: just example, should be moved to appropriate place
    useEffect(() => {
        const loadNotifications = async () => {
            const result = (await dispatch(fetchNotifications())) as PayloadAction<
                NotificationI[] | []
            >;

            if (Array.isArray(result.payload)) {
                console.log('Notifications loaded:', result.payload);
            }
        };

        loadNotifications().catch((err) => {
            console.error('Failed to load notifications', err);
        });
    }, [dispatch]);

    // const notifications = useAppSelector(getNotifications);
    // notifications.forEach((notification, index) => {
    //     if (notification.markAsReadAt === null) {
    //         console.log(
    //             `NEW NOTIFICATION, should be shown as popup [${index}]`,
    //             notification.message,
    //         );
    //     }
    // });

    return <AuthContext.Provider value={{...value,authenticationStatus}}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextProps => {
    return useContext(AuthContext);
};

import { Icon as CommonIcons } from 'src/components/Icons.tsx';

export enum SchedulingType {
    PICKUP = 'pickup',
    DROPOFF = 'dropoff',
}

export enum WorkflowStatus {
    PENDING = 'pending',
    SUBMITTED = 'submitted',
    SCHEDULING = 'scheduling',
    PENDING_APPROVAL = 'photo-approval-required',
    SHIPPING_SCHEDULED = 'shipping-scheduled',
    PENDING_IMAGE_UPLOAD = 'photo-required',
    IN_TRANSIT = 'in-transit',
    ARRIVED = 'arrived',
    PREP_FOR_SALE = 'prepare-for-sale',
    READY_FOR_AUCTION = 'ready-for-auction',
    AUCTION_SCHEDULED = 'auction-scheduled',
    LIVE_AUCTION = 'live-auction',
    AUCTION_ENDED = 'auction-ended',
    DEAL = 'deal',
    NOT_ARRIVED = 'not-arrived',
    REJECTED_IN_PREPARATION = 'rejected-in-preparation',
    REJECTED_AFTER_AUCTION = 'rejected-after-auction',
    REJECTED_WITHOUT_SHIPBACK = 'rejected-without-shipback',
    RETURNED = 'returned',
    QUICK_SALE = 'quick-sale',
}

export const ValueCategory = {
    noTouch: 'no touch',
    lowTouch: 'low touch',
    standard: 'standard',
    hv: 'high value',
    ue: 'ultra expensive',
    se: 'super expensive',
};

export const pendingSchedulingStates = [
    WorkflowStatus.SUBMITTED,
    WorkflowStatus.PENDING,
    WorkflowStatus.PENDING_APPROVAL,
    WorkflowStatus.PENDING_IMAGE_UPLOAD,
];
export const validSchedulingStates = [WorkflowStatus.SCHEDULING];
export const preScheduleStates = [...pendingSchedulingStates, ...validSchedulingStates];

export const shippingStates = [
    WorkflowStatus.SHIPPING_SCHEDULED,
    WorkflowStatus.IN_TRANSIT,
];
export const processingStates = [
    WorkflowStatus.PREP_FOR_SALE,
    WorkflowStatus.REJECTED_IN_PREPARATION,
    WorkflowStatus.QUICK_SALE,
    WorkflowStatus.RETURNED,
    WorkflowStatus.ARRIVED,
];
export const auctionStates = [
    WorkflowStatus.READY_FOR_AUCTION,
    WorkflowStatus.LIVE_AUCTION,
    WorkflowStatus.AUCTION_ENDED,
    WorkflowStatus.AUCTION_SCHEDULED,
    WorkflowStatus.DEAL,
    WorkflowStatus.REJECTED_AFTER_AUCTION,
];

export const abortedStatuses = [
    WorkflowStatus.REJECTED_IN_PREPARATION,
    WorkflowStatus.REJECTED_AFTER_AUCTION,
];

const ItemTypeIconsStyle = {
    width: '35px',
    height: '35px',
};

export const ItemTypes = [
    {
        type: 'Ring',
        icon: <CommonIcons.RingIcon sx={ItemTypeIconsStyle}/>,
        alias: 'Rings',
    },
    {
        type: 'Watch',
        icon: <CommonIcons.WatchIcon sx={ItemTypeIconsStyle}/>,
        alias: 'Watches',
    },
    {
        type: 'Necklace',
        icon: <CommonIcons.NecklaceIcon sx={ItemTypeIconsStyle}/>,
        alias: 'Necklaces',
    },
    {
        type: 'Bracelet',
        icon: <CommonIcons.BraceletIcon sx={ItemTypeIconsStyle}/>,
        alias: 'Bracelets',
    },
    {
        type: 'Earrings',
        icon: <CommonIcons.EarringsIcon sx={ItemTypeIconsStyle}/>,
        alias: 'Earrings',
    },
    {
        type: 'Loose diamond',
        icon: <CommonIcons.LooseDiamondIcon sx={ItemTypeIconsStyle}/>,
        alias: 'Loose Diamonds',
    },
    {
        type: 'Gems',
        icon: <CommonIcons.LooseDiamondIcon sx={ItemTypeIconsStyle}/>,
        alias: 'Gems',
    },
    {
        type: 'Gold',
        icon: <CommonIcons.LooseDiamondIcon sx={ItemTypeIconsStyle}/>,
        alias: 'Gold',
    },
];

import { BannerContent, BannerWrapper } from 'src/styles/banner/banner.styles.tsx';
import { Typography } from '@worthy-npm/worthy-common-ui-components';
import { useAppSelector } from 'src/store';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { getUser } from 'src/store/userSlice';

const BannerStorageKey = 'bannerClosed';

const Banner = () => {
    const user = useAppSelector(getUser);
    const [ isBannerVisible, setBannerVisible ] = useState(false);
    const [ bannerText, setBannerText ] = useState('');

    useEffect(() => {
        const bannerClosed = !!sessionStorage.getItem('bannerClosed');
        if (bannerClosed || !user) {
            setBannerVisible(false);
            return;
        }

        const creationDate = user?.createdAt;

        if (!creationDate) {
            console.info('no creation date');
            setBannerVisible(false);
            return;
        }

        const createdDate = dayjs(creationDate);
        const currentDate = dayjs();
        const daysDifference = currentDate.diff(createdDate, 'day');

        if (daysDifference > 14) {
            setBannerVisible(false);
        } else {
            setBannerVisible(true);
            if (daysDifference > 7) {
                setBannerText(`Success Fees Reduced to Only 5% When You Ship by ${ createdDate.add(14, 'day').format('MM.DD') }`);
            } else {
                setBannerText(`Success Fees Reduced to Only 5% When You Ship by ${ createdDate.add(7, 'day').format('MM.DD') }`);
            }
        }
    }, [ user ]);

    const onClose = () => {
        sessionStorage.setItem(BannerStorageKey, 'true');
        setBannerVisible(false);
    };

    if (!isBannerVisible) {
        return null;
    }

    return (
        <BannerWrapper
            icon={ false }
            onClose={ onClose }
        >
            <BannerContent>
                <Typography
                    variant="h5"
                    fontWeight="300"
                >
                    Limited Time Offer:
                </Typography>
                <Typography
                    variant="h3"
                >
                    { bannerText }
                </Typography>
            </BannerContent>


        </BannerWrapper>
    );
};

export default Banner;

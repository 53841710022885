import { Button, Paper, Stack, styled, Typography } from '@worthy-npm/worthy-common-ui-components';
import { getSubmissionUrl } from 'src/config';
import { redirectTo } from 'src/helpers/common';

const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
    color: theme.palette.text.primary,
    textAlign: 'center',
    width: '100%',
    padding: theme.spacing(5, 3),
    maxWidth: 720,
    outline: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 2),
    }
}));

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.primary,
    borderWidth: 2,
    width: 'fit-content',
    '&:hover': {
        borderColor: theme.palette.text.primary,
        color: theme.palette.text.primary,
        borderWidth: 2,
    },
}));
const MoreToSellSection = () => {
    return (
        <StyledPaper elevation={0}>
            <Stack
                direction={{xs: 'column', sm:'row'}}
                gap={3}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Typography variant="h3">Have more items to sell?</Typography>
                <StyledButton
                    variant="outlined"
                    onClick={() => redirectTo(getSubmissionUrl())}
                    disableElevation
                    size='large'
                >
                    Submit a New Item
                </StyledButton>
            </Stack>
        </StyledPaper>
    );
};

export default MoreToSellSection;

import styled from '@emotion/styled';
import { Alert, Box } from '@worthy-npm/worthy-common-ui-components';
import banner from '/img/banner.png';


const BannerWrapper = styled(Alert)(({ theme }) => ({
    backgroundImage: `url(${banner})`,
    borderRadius: 0,
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiAlert-message': {
        flex: 1,
    }
}));

const BannerContent = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    [theme.breakpoints.down('md')]: {
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: '8px',
    }
}));


export {
    BannerWrapper,
    BannerContent
}

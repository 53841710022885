import styled from '@emotion/styled';
import {
  Box,
  FormControlLabel,
  ListItem2,
} from '@worthy-npm/worthy-common-ui-components';
import TextField from '@worthy-npm/worthy-common-ui-components/components/TextField';

export const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  alignSelf: 'stretch',
}));

export const FormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(3),
  alignSelf: 'stretch',
}));

export const TextInput = styled(TextField)(() => ({
  padding: '0px',
}));

export const RadioWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingLeft: '8px',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const FormControl = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: theme.typography.caption.lineHeight,
    letterSpacing: '-0.28px',
  },
}));

export const Locations = styled(Box)(({ theme }) => ({
  bottom: '50%',
  border: '1px solid #dcdcdc',
  borderRadius: '4px',
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
  zIndex: 1000,
  maxHeight: '200px',
  overflowY: 'auto',
  [theme.breakpoints.down('md')]: {
    bottom: '10%',
  }
}));

export const Location = styled(ListItem2)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 16px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
}));

export const Street = styled.span(({ theme }) => ({
  fontWeight: 600,
  fontSize: theme.typography.fontSize,
}));

export const State = styled.span(() => ({
  color: '#757575',
  fontSize: '12px',
}));

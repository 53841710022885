import { Stepper, styled } from '@worthy-npm/worthy-common-ui-components';

export const StyledStepper = styled(Stepper, {
  shouldForwardProp: (prop) => !['color', 'currentStepIndex'].includes(prop as string),
})<{ color: string; currentStepIndex: number }>(({ theme, color, currentStepIndex }) => ({
  overflow: 'auto',
  '& .MuiStepLabel-label': {
    fontWeight: 300,
  },
  '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
    marginTop: 0,
  },
  '& .MuiStepConnector-line': {
    borderTopWidth: theme.spacing(0.5),
    borderColor: theme.palette.divider,
    
  },
  '& .Mui-completed .MuiStepConnector-line': {
    borderColor: color,
  },
  '& .Mui-active .MuiStepConnector-line': {
    borderColor: color,
  },
  [`& .MuiStep-root:nth-of-type(${currentStepIndex + 1}) .MuiStepLabel-label.MuiStepLabel-alternativeLabel`]:
    {
      fontWeight: 600,
      color: color,
    },
  '& .MuiStepLabel-iconContainer': {
    backgroundColor: 'transparent',
    zIndex: 1,
  },
  '& .MuiStepConnector-root': {
    right: 0,
    left: 0,
    top: theme.spacing(0.7),
    width: '100%',
    backgroundColor: theme.palette.divider,
  },
  '& .MuiStepLabel-active ~ .MuiStepLabel-root .MuiStepLabel-label': {
    color: color,
  },
  '& .MuiStepLabel-labelContainer': {
    color: theme.palette.grey[600],
  },
  // for curcing the edges of the first and last step
  [`& .MuiStep-root:nth-of-type(${1}) .MuiStepConnector-root`]:
    {
      borderTopLeftRadius: theme.spacing(0.5),
      borderBottomLeftRadius: theme.spacing(0.5),
    },
  [`& .MuiStep-root:nth-of-type(${1}) .MuiStepConnector-line`]:
    {
      borderTopLeftRadius: theme.spacing(0.5),
      borderBottomLeftRadius: theme.spacing(0.5),
    },
  [`& .MuiStep-root:last-child .MuiStepConnector-line`]:
    {
      borderTopRightRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
    },
  [`& .MuiStep-root:last-child .MuiStepConnector-root`]:
    {
      borderTopRightRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
    },
  // for curcing the edges of the first and last step of the active step
  [`& .MuiStep-root .Mui-active .MuiStepConnector-line`]:
    {
      borderTopRightRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
    },
}));

import { Icon, Popover, Typography } from '@worthy-npm/worthy-common-ui-components';
import {
    CalendlySchedule, CallDotsContent, CallDotsItem,
    CallScheduled, NotSureText,
    ScheduleCallBtn,
    ScheduleCallWrapper,
    ScheduleDots,
} from 'src/styles/faq/scheduleCall.styles.tsx';
import React, { useEffect, useState } from 'react';
import Calendly from 'src/helpers/calendly.ts';
import { useAppSelector } from 'src/store';
import { formatDateTimeRange, getMainItemByValueCategory } from 'src/helpers/common.ts';
import { getUser } from 'src/store/userSlice';
import { getItemAppointment } from 'src/store/itemsSlice';
import { Icon as CommonIcons } from 'src/components/Icons.tsx';
import { Item } from 'src/types/item.ts';

interface ScheduleCallProps {
    itemList: Item[];
}

const ScheduleCall = ({ itemList }: ScheduleCallProps) => {
    const user = useAppSelector(getUser);

    const [ open, setOpen ] = useState(false);
    const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement | null>(null);
    const [ mainItem, setMainItem ] = useState<Item | undefined>(undefined);
    const appointment = useAppSelector((state) => getItemAppointment(state, mainItem?.publicId));
    const [ scheduled, setScheduled ] = useState<string>('');

    useEffect(() => {
        window.addEventListener('message', handleCalendly);
    }, []);

    useEffect(() => {
        const items = itemList || [];
        if (items.length > 0) {
            const watch = items.find((item) => item.itemType?.toLowerCase().includes('watch'));
            const callableItem = watch ? watch : getMainItemByValueCategory(items);
            setMainItem(callableItem);
        }

        if (appointment && !isDatePassed(new Date(appointment.endTime))) {
            const date = formatDateTimeRange(appointment.startTime, appointment.endTime);
            setScheduled(date);
        } else {
            setScheduled('');
        }

    }, [ appointment, itemList ]);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const handleCalendly = (event: MessageEvent) => {
        if (Calendly.isCalendlyEvent(event)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            if (event.data.event.indexOf('event_scheduled') > -1) {
                console.info('Calendly event_scheduled', event.data);
            }
        }
    };

    const isDatePassed = (date: Date) => {
        const now = new Date();
        return date < now;
    };

    const handleScheduleCall = () => {
        const link = Calendly.getCalendlyLinkByValueCategory(mainItem);
        Calendly.showPopupWidget(link, Calendly.calendlyParams(mainItem, user));
    };

    const rescheduleCall = () => {
        if (!appointment) {
            handleScheduleCall();
            return;
        }
        Calendly.showPopupWidget(appointment.rescheduleUrl, Calendly.calendlyParams(mainItem, user));
    };

    const cancelCall = () => {
        if (!appointment) {
            return;
        }
        Calendly.showPopupWidget(appointment.cancelUrl, Calendly.calendlyParams(mainItem, user));
    };

    return (
        <ScheduleCallWrapper>
            { !scheduled && (
                <CalendlySchedule>
                    <NotSureText variant='h5' maxWidth={{xs: 220, sm: 150}}>
                        Want to speak with our team?
                    </NotSureText>
                    <ScheduleCallBtn
                        data-automation="schedule-general-call"
                        variant="contained"
                        size="large"
                        onClick={ handleScheduleCall }
                        disableElevation
                    >
                          Schedule Call
                    </ScheduleCallBtn>
                </CalendlySchedule>
            ) }

            { scheduled && (
                <CallScheduled>
                    <ScheduleDots
                        onClick={ handleOpen }
                        variant="default"
                        size="small"
                        color="secondary"
                    >
                        <CommonIcons.VertThreeDots />
                    </ScheduleDots>
                    <Popover
                        anchorOrigin={ {
                            vertical: 'center',
                            horizontal: 'right',
                        } }
                        transformOrigin={ {
                            vertical: 'top',
                            horizontal: 'left',
                        } }
                        open={ open }
                        onClose={ handleClose }
                        anchorEl={ anchorEl }
                    >
                        <CallDotsContent>
                            {/* <CallDotsItem> */ }
                            {/*     <Icon.CalendarAdd /> */ }
                            {/*     <Typography variant="caption">Add to calendar</Typography> */ }
                            {/* </CallDotsItem> */ }
                            <CallDotsItem
                                onClick={ rescheduleCall }
                            >
                                <Icon.CalendarReschedule />
                                <Typography variant="caption">Reschedule call</Typography>
                            </CallDotsItem>
                            <CallDotsItem
                                onClick={ cancelCall }
                                cancel={ true }
                            >
                                <Icon.CalendarCancel />
                                <Typography
                                    variant="caption"
                                    color="text.primary"
                                >
                                    Cancel call
                                </Typography>
                            </CallDotsItem>
                        </CallDotsContent>
                    </Popover>
                    <Typography
                        variant="h3"
                        textAlign="center"
                        fontSize="24px"
                    >
                      Call Scheduled For:
                    </Typography>
                    <Typography
                        variant="caption"
                        textAlign="center"
                        fontWeight="300"
                    >
                        { scheduled }
                    </Typography>
                </CallScheduled>
            ) }
        </ScheduleCallWrapper>
    );
};

export default ScheduleCall;

import { HIWProps } from 'src/components/infoSections/hiw.tsx';
import submit from '/img/granSubmit.jpeg';
import shipping from '/img/shipping.jpeg';
import preSale from '/img/preSale.jpeg';
import auction from '/img/auction.png';
import pay from '/img/pay.jpeg';

const StepsData = [
    {
        title: 'Submit item',
        description: 'We’ll ask you some basic questions about your jewelry.',
        img: submit,
    },
    {
        title: 'Schedule Shipping',
        description:
            'Choose from FedEx pickup or FedEx drop-off. Shipping is fully covered by Worthy and fully insured.',
        img: shipping
    },
    {
        title: 'Prep for Sale',
        description:
            'Our team of gemologists and 3rd party, objective graders will prepare your jewelry for auction by cleaning, grading, and photographing it for you.',
        img: preSale
    },
    {
        title: 'Online Auction',
        description:
            'Once you set a reserve price and start your online auction, you\'ll be able to watch the offers roll in.',
        img: auction
    },
    {
        title: 'Get Paid',
        description:
            "Once your item is sold, you'll get the final sale amount minus Worthy's fee, which is a percentage of the sale price.",
        img: pay
    },
];

export const HIWForSchedulingPropsData: HIWProps = {
  steps: StepsData,
  title: 'How it works?',
  moreInfoLink: 'https://www.worthy.com/',
  currentStepIndex: 1,
};

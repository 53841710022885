import {
    Accordion,
    AccordionSummary,
    Box,
    StepConnector,
    StepContent,
    Stepper,
    styled,
} from '@worthy-npm/worthy-common-ui-components';
import ExpandMoreShineIcon from '@worthy-npm/worthy-common-ui-components/components/Icon/ExpandMoreShine';
import { Icon } from 'src/components/Icons.tsx';

const NumberWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  // justifyContent: 'space-around',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    marginBottom: 0,
  },
}));

const StyledArrowIcon = styled(Icon.SlimArrow, {
    shouldForwardProp: (prop) => ![ 'doneStep' ].includes(prop as string),
})<{ doneStep?: boolean }>(({ theme, doneStep }) => ({
    fontSize: 30,
    '& path': {
        stroke: doneStep ? theme.palette.secondary.main : theme.palette.highlight.main,
    },
    [theme.breakpoints.down('md')]: {
        fontSize: 18,
    },
}));

const StepWrapper = styled(Box, {shouldForwardProp(propName) {
    return !['index', 'currentStepIndex'].includes(propName as string);
},})<{ index: number; currentStepIndex: number }>(
    ({ theme, index, currentStepIndex }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: theme.spacing(1),
        color:
            currentStepIndex === index
                ? theme.palette.highlight.main
                : currentStepIndex > index
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
    }),
);

const Process = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
        gap: theme.spacing(0),
        maxWidth: '74px',
    },
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    color: theme.palette.highlight.main,
    background: 'transparent',
    boxShadow: 'none',
    '&:before': {
        backgroundColor: theme.palette.primary.main,
    },
    '& .MuiAccordionSummary-content': {
        margin: theme.spacing(1, 0, 1),
        '&.Mui-expanded': {
            margin: 0,
            justifyContent: 'end',
            gap: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    },
}));

// todo duplicated code
const StyledExpandMoreIcon = styled(ExpandMoreShineIcon)(({ theme}) => ({
    color: theme.palette.highlight.main,
}));

const AccordionSummaryWrapper = styled(AccordionSummary,{
    shouldForwardProp: (prop) => prop !== 'showPreview',
})<{showPreview: boolean}>(({ showPreview, theme })=>({
    flexDirection: showPreview ? 'column': 'row',
    textAlign: showPreview ? 'center': 'right',
    '&.Mui-expanded': {
        minHeight: 'unset',
        padding: theme.spacing(1, 0),
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        fontSize: '1.1rem',
    }
}));

const StepperWrapper = styled(Stepper)`
    & .MuiStepLabel-vertical {
        padding: 0;
        height: 15px;
    }

    & .MuiStepper-root {
        margin-right: 8px;
    }

    & .MuiStepConnector-root {
        margin-left: 4px;
    }

    & .MuiStepConnector-root .MuiStepConnector-line {
        height: 100%;
    }
`;

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`& .MuiStepConnector-line`]: {
        marginLeft: '3px',
        borderLeftWidth: '2px',
        borderLeftStyle: 'solid',
        borderLeftColor: theme.palette.tertiary.main,
    },

    [theme.breakpoints.down('md')]: {
        [`& .MuiStepConnector-line`]: {
            marginLeft: '3px',
        },
    },
}));

const ContentStepWrapper = styled(StepContent)<{ item: number }>(({ theme, item }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    justifyContent: 'center',
    padding: theme.spacing(2),
    borderLeftColor: item === 4 ? 'transparent' : theme.palette.tertiary.main,
    marginLeft: '7px',
    borderLeftWidth: '2px',
    borderLeftStyle: 'solid',
    alignItems: 'flex-start',
}));
export { AccordionSummaryWrapper, ColorlibConnector, ContentStepWrapper, NumberWrapper, Process, StepperWrapper, StepWrapper, StyledAccordion, StyledArrowIcon, StyledExpandMoreIcon } ;

import { useEffect, useMemo, useRef, useState } from 'react';
import BaseLayout from 'src/layout/baseLayout';
import HIW from 'src/components/infoSections/hiw.tsx';
import FAQ from 'src/components/infoSections/faq.tsx';
import ScheduleCall from 'src/components/scheduleCall/scheduleCall.tsx';
import { useAppDispatch, useAppSelector } from 'src/store';
import SchedulingCTA from 'src/components/SchedulingCTA';
import {
  fetchItems,
  getIsAllPending,
  getItems,
  selectError,
  selectLoading,
  setIsAllPending,
} from 'src/store/itemsSlice';
import {
  isSchedulingOpen,
  resetShippingState,
  setLocations,
  setOpenScheduling,
  setScheduledItems,
  setSchedulingMainItem,
} from 'src/store/schedulingSlice';
import { SECTION_TYPES } from 'src/constants/scheduling.tsx';
import { usePreSchedulingItems } from 'src/hooks/usePreSchedulingItems.ts';
import SchedulingList from 'src/views/SchedulingList';
import NewItemSubmit from 'src/components/newItemSubmit';
import { HIWForSchedulingPropsData } from 'src/data/HIWForSchecduling';
import { Box, Unstable_Grid2 as Grid } from '@worthy-npm/worthy-common-ui-components';
import Survey from 'src/components/survey/survey.tsx';
import { Item } from 'src/types/item.ts';
import { LoadingDiamondImg } from 'src/components/loader.tsx';
import ItemsEmptyPage from 'src/views/ItemsEmpty.tsx';
import { useDesktopVersion } from 'src/helpers/deviceSize.ts';
import SchedulingPopUp from 'src/components/SchedulingPopUp/SchedulingPopUp.tsx';
import _ from 'lodash';
import { SchedulingQuestions } from 'src/data/SchedulingFaqs';
import { isUltraValueLeads } from 'src/helpers/common.ts';
import Calendly from 'src/helpers/calendly.ts';
// import { items } from 'src/data/Item';

const Shipping = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoading);
  const error = useAppSelector(selectError);
  const itemList = useAppSelector(getItems);
  // const itemList = items;
  const openSchedulingPopup = useAppSelector(isSchedulingOpen);
  const allPending = useAppSelector(getIsAllPending);
  const [isSurveyOpen, setIsSurveyOpen] = useState(false);
  const [surveyItem, setSurveyItem] = useState<Item>();
  const deselectedItems = useRef<Set<string>>(new Set());

  const isPC = useDesktopVersion();

  const groupedItemsMemo = usePreSchedulingItems(itemList);
  const pendingItems = useMemo(
    () => groupedItemsMemo[SECTION_TYPES.PENDING] || [],
    [groupedItemsMemo],
  );
  const validItems = useMemo(
    () => groupedItemsMemo[SECTION_TYPES.VALID] || [],
    [groupedItemsMemo],
  );

  const [selectedItems, setSelectedItems] = useState<Item[]>(validItems);
  const [ isUltraValue, setIsUltraValue ] = useState(false);

  useEffect(() => {
    dispatch(resetShippingState());
  }, [])

  useEffect(() => {
    Calendly.loadCalendly();

    return () => {
      Calendly.removeCalendly();
    };
  }, []);

  useEffect(() => {
    document.title = 'Scheduling';
    !itemList.length && dispatch(fetchItems());

    countPending();
  }, [dispatch, itemList.length]);

  useEffect(() => {
    setSelectedItems((prevSelectedItems) => {
      const validSelectedItems = prevSelectedItems.filter((item) =>
          validItems.some(validItem => validItem.publicId === item.publicId)
      );
      const prevSelectedIds = new Set(validSelectedItems.map((item) => item.publicId));
      const newItems = validItems.filter(
          (item) =>
              !deselectedItems.current.has(item.publicId) && !prevSelectedIds.has(item.publicId) && !item.publicId.includes('temp'),
      );
      return [ ...validSelectedItems, ...newItems ];
    });

    countPending();
  }, [ validItems ]);

  const countPending = () => {
    const totalLength = (_.get(groupedItemsMemo, 'Valid', []).length) + (_.get(groupedItemsMemo, 'Pending', []).length);

    const noSelectedValidItems = validItems.every(item => deselectedItems.current.has(item.publicId));

    const isOnlyPending =
        totalLength === pendingItems.length || // Все айтемы Pending
        (pendingItems.length > 0 && validItems.length > 0 && noSelectedValidItems);

    dispatch(setIsAllPending(isOnlyPending));
  }

  useEffect(() => {
    dispatch(setSchedulingMainItem(selectedItems));
    dispatch(setScheduledItems(selectedItems.map(item => (item.publicId))));
    setIsUltraValue(selectedItems.some(item => isUltraValueLeads(item)));
  }, [selectedItems]);

  useEffect(() => {
    const searchUl = new URLSearchParams(location.search);
    const showItemSurvey = searchUl.get('survey');
    const searchItemId = searchUl.get('itemId');
    if (itemList.length && showItemSurvey && searchItemId) {
      const itemToSurvey = itemList.find((item) => item.publicId === searchItemId);
      if (itemToSurvey) {
        setSurveyItem(itemToSurvey);
        setIsSurveyOpen(true);
      }
    }
  }, [itemList]);

  const onSurveyClose = () => {
    setIsSurveyOpen(false);
    window.history.replaceState({}, '', `${location.origin}${location.pathname}`);
  };

  const handleCheckboxChange = (item: Item, isChecked: boolean) => {
    if (isChecked) {
      deselectedItems.current.delete(item.publicId);
    } else {
      deselectedItems.current.add(item.publicId);
    }

    countPending();

    setSelectedItems((prevSelectedItems) => {
      if (isChecked) {
        return prevSelectedItems.some((stateItem) => stateItem.publicId === item.publicId)
            ? prevSelectedItems
            : [ ...prevSelectedItems, item ];
      } else {
        return prevSelectedItems.filter(
            (stateItem) => stateItem.publicId !== item.publicId,
        );
      }
    });
    dispatch(setLocations([]));
  };

  const handleScheduleClick = () => {
    dispatch(setSchedulingMainItem(selectedItems));
    dispatch(setScheduledItems(selectedItems.map(item => (item.publicId))));
    dispatch(setLocations([]));
    dispatch(setOpenScheduling(true as boolean));
    setIsUltraValue(selectedItems.some(item => isUltraValueLeads(item)));
  };

  if (error) {
    return (
        <BaseLayout>
          <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              margin="100px"
          >
            <LoadingDiamondImg />
          </Box>
        </BaseLayout>
    )
  }

  const getItemsComponent = () => {
    if (loading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin="100px"
        >
          <LoadingDiamondImg />
        </Box>
      );
    }

    if (pendingItems.length === 0 && validItems.length === 0) {
      return (
        <ItemsEmptyPage
          title="You have no items ready to ship..."
          body="Tap below to add a new item and start selling."
        />
      );
    }

    return (
      <Grid
          container
          justifyContent="center"
          width="100%"
          maxWidth="1000px"
          margin="0 auto"
          columnSpacing={{md:6}}
      >
        <Grid xs={12} md={7} justifyContent="center">
          <SchedulingList
              items={itemList}
              onCheckboxChange={handleCheckboxChange}
          />
          {validItems && <NewItemSubmit /> }
        </Grid>
        {
          isPC && (
            <Grid xs={5} justifyContent="center">
              <SchedulingPopUp isPC={isPC} />
              {
                !(isUltraValue || allPending || !selectedItems.length) && (
                      <ScheduleCall
                          itemList={validItems}
                      />
                  )
              }

            </Grid>
          )
        }
      </Grid>
    );
  };

  return (
    <BaseLayout>
      { getItemsComponent() }
      <Box p={2} marginTop={{xs: 1, md: 0}}>
        <HIW {...HIWForSchedulingPropsData} />
      </Box>
      {
        !isPC && (
              <ScheduleCall
                  itemList={validItems}
              />
          )
      }

      <FAQ questions={SchedulingQuestions}/>
      {!isPC && !openSchedulingPopup &&
        (<SchedulingCTA
          disabled={false}
          itemsCount={selectedItems.length}
          onBtnClick={handleScheduleClick}
        />)
      }
      {isSurveyOpen && surveyItem && (
        <Survey
          open={isSurveyOpen}
          onClose={onSurveyClose}
          item={surveyItem}
        />
      )}
    </BaseLayout>
  );
};

export default Shipping;

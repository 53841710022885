import { Button, Paper, Stack, styled, Typography } from '@worthy-npm/worthy-common-ui-components';
import { ButtonProps } from '@worthy-npm/worthy-common-ui-components/components/Button/Button';
import { PropsWithChildren } from 'react';

export const DescriptionText = ({ children }: PropsWithChildren) => {
  return <Typography variant="body1">{children}</Typography>;
};

const StyledButton = styled(Button)(({ theme }) => ({
  alignItems: 'center',
  textWrap: 'nowrap',
  height: '47px',
  minWidth: '200px',
  alignSelf: 'end',
  ['&:disabled']: {
    color: theme.palette.grey[500],
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  }
}));

export const CtaButton = ({ children, ...props }: ButtonProps) => {
  return (
    <StyledButton
      variant="contained"
      disableElevation
      color="highlight"
      size="large"
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    height: '100%',
    flexDirection : 'row',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
  }
}));

export const StyledAccordionStepperWrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  border: `1.5px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));


import { useEffect, useMemo, useState } from 'react';
import {
    Autocomplete,
     TextField, Stack,
    ListItemText,
    IconButton,
    Icon as CommonIcon
} from '@worthy-npm/worthy-common-ui-components';
import { Icon } from 'src/components/Icons.tsx';
import { debounce } from 'lodash';
import {
    AddressSuggestion,
    getPlaceDetails,
    getPlacePredictions,
    initializeGoogleMapsServices,
} from 'src/components/AddressInput/googleMapsHelper.tsx';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getFormattedAddress, setFormattedAddress } from 'src/store/schedulingSlice';
import { addressToFormattedAddress } from 'src/helpers/scheduling/common';
import {
  Location,
  Street,
  State,
} from 'src/components/AddressInput/AddressInput.styles.tsx';


const AutoCompleteAddress = () => {
  const dispatch = useAppDispatch();
    const [ autocompleteService, setAutocompleteService ] =
        useState<google.maps.places.AutocompleteService | null>(null);
    const [ placesService, setPlacesService ] = useState<google.maps.places.PlacesService | null>(
        null,
    );
    const [ suggestions, setSuggestions ] = useState<AddressSuggestion[]>([]);
    const formattedAddress = useAppSelector(getFormattedAddress);
    const [ inputValue, setInputValue ] = useState(formattedAddress);
    const [selectedSuggestion, setSelectedSuggestion] = useState<AddressSuggestion | null>(null);
    const [isEditing, setIsEditing] = useState(!formattedAddress);

    useEffect(() => {
        const { autocompleteService, placesService } = initializeGoogleMapsServices();
        setAutocompleteService(autocompleteService);
        setPlacesService(placesService);
    }, []);

    const fetch = useMemo(
        () =>
          debounce(
            (
              input: string,
              callback: (results?: readonly AddressSuggestion[]) => void,
            ) => {
                if(autocompleteService){
                    return getPlacePredictions(autocompleteService, input).then((results) => {
                        callback(results);
                    })
                }
            },
            700,
          ),
        [autocompleteService],
      );

      
    useEffect(() => {
        let active = true;
    
        if(!autocompleteService){
            return undefined;
        }
    
        if (inputValue === '') {
          setSuggestions(selectedSuggestion ? [selectedSuggestion] : []);
          dispatch(setFormattedAddress(''));
          return undefined;
        }

        if(selectedSuggestion?.description === inputValue){
            return undefined;
        }
        void fetch(inputValue, (results?: readonly AddressSuggestion[]) => {
          if (active) {
            let newOptions: readonly AddressSuggestion[] = [];
    
            if (selectedSuggestion) {
              newOptions = [selectedSuggestion];
            }
    
            if (results) {
              newOptions = [...newOptions, ...results];
            }
    
            setSuggestions(newOptions as AddressSuggestion[]);
          }
        });
    
        return () => {
          active = false;
        };
      }, [dispatch,selectedSuggestion, inputValue, fetch, autocompleteService ]);

    const updateAddress = (suggestion: AddressSuggestion) => {

        if (placesService) {
          getPlaceDetails(placesService, suggestion.place_id).then(({ addressStreet, addressCity, addressState, addressZipcode }) => {
            dispatch(setFormattedAddress(addressToFormattedAddress({
              street: addressStreet,
              city: addressCity,
              state: addressState,
              zipcode: addressZipcode,
            })))
          }).catch((error) => {
                console.error('Error fetching place details:', error);
            });
        }
    };

    const onEditEnable = () => {
        setInputValue('');
        setIsEditing(true)
        dispatch(setFormattedAddress(''));
    }
    return (
        <Stack direction={'row'} width={'100%'}>
            <Autocomplete
                  getOptionLabel={(option: AddressSuggestion) =>
                typeof option === 'string' ? option : option.description
              }
              options={suggestions}
              autoComplete
              includeInputInList
              fullWidth
              disabled={!isEditing}
              filterSelectedOptions
              value={selectedSuggestion}
              noOptionsText="No locations"
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              onChange={(e, newValue: AddressSuggestion | null) => {
                  if(newValue){
                      updateAddress(newValue);
                  }
                setSuggestions(newValue ? [newValue, ...suggestions] : suggestions);
                setSelectedSuggestion(newValue);
        
              }}
              renderInput={(params) => (
                <TextField variant='standard' {...params} label={isEditing ? "Street Address" : 
                formattedAddress
              } value={inputValue} fullWidth InputLabelProps={{
                  sx: {
                    paddingLeft: 2,
                    color: 'secondary.main',
                    '&.Mui-focused':{
                      color: 'secondary.main',
                    },
                    '&.Mui-disabled': {
                      color: 'text.primary',
                      paddingLeft: 0,
                    },
                  },
                }}
                />
              )}
              renderOption={(props, option: AddressSuggestion) => {
                const {  ...optionProps } = props;
        
                return (
                  <>
                  <Location
                  {...optionProps}
                  key={ option.place_id }
                  >
                      <Icon.LocationIcon sx={ { marginRight: '16px' } } />
                      <ListItemText
                          primary={
                              <Street>
                                  { option.structured_formatting.main_text }
                              </Street>
                          }
                          secondary={
                              <State>
                                  { option.structured_formatting.secondary_text }
                              </State>
                          }
                      />
                  </Location>
                  </>
              
                );
              }}
            />
            {!isEditing && (
                <IconButton  onClick={onEditEnable}  disableRipple sx={{ borderRadius: '0px',p:0.5, marginLeft: '1px', borderBottom: '1px dotted rgba(0, 0, 0, 0.42)' ,alignItems:'end'}}>
                  <CommonIcon.Edit fontSize='small' /> 
                </IconButton>)}
        </Stack>
    );
};

export default AutoCompleteAddress;

import { Box, Icon } from '@worthy-npm/worthy-common-ui-components';
import { useAppDispatch, useAppSelector } from 'src/store';
import { closeScheduling, getLoading, prevStep, setError, setFlowToStart, setLoading } from 'src/store/schedulingSlice';
import { Header, NavIconBtn, Title, TitleWrapper } from 'src/components/PickupSlots/PickupSlots.styles.tsx';
import worthyShineTheme from '@worthy-npm/worthy-common-ui-components/theme/worthyShineTheme';
import { Icon as ServeIcon } from '../Icons';

interface SuccessScheduleProps {
    title: string;
    firstStep?: boolean;
    isPC: boolean;
}

const SchedulingHeader = ({ title, firstStep = false, isPC }: SuccessScheduleProps) => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(getLoading);

    const resetErrors = () => {
        dispatch(setError(''));
        dispatch(setLoading(false));
    }

    const onClose = () => {
        if (isLoading) {
            return;
        }

        resetErrors();

        if (!isPC) {
            return dispatch(closeScheduling());
        }
        dispatch(setFlowToStart());
    }

    const onBack = () => {
        if (isLoading) {
            return;
        }

        dispatch(prevStep());
        resetErrors();
    }

    return (
        <Header>
            <Box
                sx={ {
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: worthyShineTheme.spacing(2),
                    alignSelf: 'stretch',
                } }
            >
                {
                    !firstStep && (
                        <NavIconBtn
                            variant="default"
                            size="small"
                            color="secondary"
                            data-automation="scheduling-close"
                            style={ { width: '24px', height: '24px' } }
                            onClick={ onBack }
                        >
                            <ServeIcon.BackArrowIcon
                                sx={ {
                                    width: '24px',
                                    height: '24px',
                                } }
                            />
                        </NavIconBtn>
                    )
                }

                <TitleWrapper>
                    <Box
                        sx={ {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            alignSelf: 'stretch',
                        } }
                    >
                        <Title variant='h5'>{ title }</Title>
                        {
                            (!firstStep || !isPC) && (
                                <NavIconBtn
                                    variant="default"
                                    size="small"
                                    color="secondary"
                                    data-automation="scheduling-close"
                                    style={ { width: '24px', height: '24px' } }
                                    onClick={ onClose }
                                >
                                    <Icon.CloseIcon style={ { width: '24px', height: '24px' } } />
                                </NavIconBtn>
                            )
                        }

                    </Box>
                </TitleWrapper>
            </Box>
        </Header>
    );
};

export default SchedulingHeader;

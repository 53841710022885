import { StyledNavToolBar, TabsContainer } from 'src/styles/nav/navigation.styles.tsx';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Tab } from '@worthy-npm/worthy-common-ui-components';
import { routes } from 'src/routes.tsx';
import { useAppDispatch, useAppSelector } from 'src/store';
import React, { useEffect, useState } from 'react';
import { getRoute, setRoute } from 'src/store/sharedSlice';
import { selectError } from 'src/store/itemsSlice';
import { useDesktopVersion } from 'src/helpers/deviceSize.ts';

const Navigation = () => {
    const location = useLocation();
    const rootPath = useAppSelector(getRoute);
    const error = useAppSelector(selectError);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [currentPath, setCurrentPath] = useState(rootPath);
    const tabRoutes = routes[0].children.map((route) => route.path);
    const filteredRoutes = tabRoutes.filter((route) => route !== '/');
    const isPc = useDesktopVersion();

    useEffect(() => {
        if (location.pathname !== rootPath) {
            setCurrentPath(rootPath);
            navigate(rootPath);
            window.scrollTo(0, 0);
        }
    }, [rootPath, location.pathname]);

    const handleTabChange = (event: React.SyntheticEvent<Element>, newValue: string) => {
        setCurrentPath(newValue);
        dispatch(setRoute(newValue));
    };

    return (
        <StyledNavToolBar position="sticky" elevation={0}>
            <TabsContainer
                value={currentPath}
                variant={ isPc ? 'standard' : 'fullWidth' }
                selectionFollowsFocus
                centered
                onChange={handleTabChange}
            >
                {filteredRoutes.map((route) => (
                    <Tab
                        key={route}
                        className="tab-wrapper"
                        label={route === '/shipping' ? 'Shipping' : 'My Items'}
                        value={route}
                        to={route}
                        component={Link}
                        disabled={!!error}
                    />
                ))}
            </TabsContainer>
        </StyledNavToolBar>
    );
};

export default Navigation;

import { Button, Stack, styled, Typography, Icon } from '@worthy-npm/worthy-common-ui-components';
import {  getSubmissionUrl } from 'src/config';
import { redirectTo } from 'src/helpers/common';
import { setRoute } from 'src/store/sharedSlice';
import { useAppDispatch } from 'src/store';

const StyledStack = styled(Stack)(({ theme }) => ({
    height: '100%',
    gap: theme.spacing(2),
    alignItems: 'center',
    minHeight: 400,
    justifyContent: 'center',
    textAlign: 'center',
    maxWidth: 300,
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
        maxWidth: 450,
    },
}));
const ItemsEmptyPage = ({ title, body, pendingScheduling }: { title?: string, body?: string, pendingScheduling?: boolean }) => {
    const dispatch = useAppDispatch();
    return pendingScheduling ? (
        <StyledStack>
            <Typography variant="h3">Shipping method not yet selected</Typography>
            <Typography
                variant="body1"
                fontWeight={'light'}
            >
                Your items will appear here after you confirm your shipping method.
            </Typography>
            <Button
                color="highlight"
                variant="contained"
                disableElevation
                onClick={() => dispatch(setRoute('/shipping'))}
                size='large'
            >
                Select Shipping Method
            </Button>
        </StyledStack>
    ) : (
        <StyledStack minWidth={250}>
            <Typography variant="h3">{title}</Typography>
            <Typography
                variant="body1"
                fontWeight={'light'}
            >
                {body}
            </Typography>
            <Button
                color="highlight"
                variant="contained"
                disableElevation
                startIcon={<Icon.Add />}
                size='large'
                onClick={() => redirectTo(getSubmissionUrl())}

            >
                Submit a new item
            </Button>
        </StyledStack>
    );
};

export default ItemsEmptyPage;

import { backendService } from 'src/services/api/backend.service.ts';
import { createAppSlice } from 'src/store/createAppSlice.ts';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {  NotificationI, NotificationTypes } from 'src/types/notification.ts';
import { SchedulingType } from 'src/constants/item.constants';

interface NotificationsState {
    notifications: NotificationI[];
    loading: boolean;
    error: string | null;
}

const initialState: NotificationsState = {
    notifications: [],
    loading: false,
    error: null,
};

export const markNotificationAsRead = createAsyncThunk<void, string>(
    'notifications/markNotificationAsRead',
    async (notificationId) => {
      return backendService.markNotificationAsRead(notificationId);
    }
  );
  
export const notificationsSlice = createAppSlice({
    name: 'notifications',
    initialState,
    reducers: (create) => ({
        updateNotification: create.reducer((state, action: PayloadAction<NotificationI>) => {
            let newNotification = true;

            state.notifications = state.notifications.map((notification) => {
                if (notification && notification.id === action.payload.id) {
                    newNotification = false;
                    return { ...notification, ...action.payload };
                } else {
                    return notification;
                }
            });

            if (newNotification) {
                state.notifications.push(action.payload);
            }
        }),

        fetchNotifications: create.asyncThunk<NotificationI[] | []>(
            async () => {
                return backendService.fetchNotifications();
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                fulfilled: (state, action: PayloadAction<NotificationI[] | []>) => {
                    state.notifications = action.payload;
                    state.loading = false;
                },
                rejected: (state) => {
                    state.loading = false;
                },
            },
        ),
        addOfflineScheduledNotification: create.reducer((state, action: PayloadAction<SchedulingType>) => {
            const currentDate = new Date();
            const notification: NotificationI = {
                type: NotificationTypes.SHIPMENT_SCHEDULED,
                id: `${currentDate.getTime()}`,
                context:{
                    shipmentType: action.payload,
                },
                markAsReadAt: null,
                createdAt: currentDate.toISOString(),
                userId: 123,    // its safe for offline mode
                offline: true,
            }
            state.notifications.push(notification);
          }),
    }),
    extraReducers: (builder) => {
        builder
          .addCase(markNotificationAsRead.pending, (state) => {
            state.loading = true;
          })
          .addCase(markNotificationAsRead.fulfilled, (state, action) => {
            const notificationId = action.meta.arg;
                state.notifications = state.notifications.map((notification) => {
                    if (notification.id === notificationId) {
                        return { ...notification, markAsReadAt: new Date().toISOString() };
                    } else {
                        return notification;
                    }
                });
            state.loading = false;
          })
          .addCase(markNotificationAsRead.rejected, (state, action) => {
            const notificationId = action.meta.arg;
            console.log('rejected markNotificationAsRead ', notificationId);
          });
      },
    selectors: {
        selectLoading: (state) => state.loading,
        selectError: (state) => state.error,
        getNotifications: (state) => state.notifications,
    },
});

export const { selectLoading, selectError, getNotifications } = notificationsSlice.selectors;
export const { fetchNotifications, updateNotification, addOfflineScheduledNotification } = notificationsSlice.actions;

import styled from '@emotion/styled';
import { FormControlLabel, Radio } from '@worthy-npm/worthy-common-ui-components';

export const RadioBtn = styled(Radio)(({theme}) => ({
  color: theme.palette.divider,
  '&.Mui-checked': {
    color: theme.palette.highlight.main,
  },

  '&.Mui-disabled': {
    color: theme.palette.divider,
  }
}));

export const FormControl = styled(FormControlLabel)(({theme}) => ({
  marginRight: 0,

  '& .MuiFormControlLabel-label': {
    fontSize: '18px',
    fontWeight: 600,
  },
  '& .MuiFormControlLabel-label.Mui-disabled': {
    color: theme.palette.divider,
  },
}));

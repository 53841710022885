import React, { PropsWithChildren } from 'react';
import { SnackbarProvider } from 'notistack';
import ItemNotification from 'src/components/Notification/ItemNotification';
import SuccessNotification from 'src/components/Notification/SuccessNotification';
import './UINotificationProvider.css'; // Import the CSS file

declare module "notistack" {
    interface VariantOverrides {
        itemNotification: true;
    }
  }

const UINotificationProvider: React.FC<PropsWithChildren> = ({ children }) => {
    
    return (
    <SnackbarProvider
      maxSnack={4}
      classes={{
        containerRoot: 'snackbar-container'
      }}
      Components={{
        itemNotification: ItemNotification,
        success: SuccessNotification,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default UINotificationProvider;
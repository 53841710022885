import { SchedulingType } from "src/constants/item.constants";
import { Item } from "./item";

export enum NotificationTypes {
  ITEM_SUBMITTED = 'itemSubmitted',
  ITEM_REJECTED = 'itemRejected',
  ITEM_NOT_ARRIVED = 'itemNotArrived',
  SHIPMENT_SCHEDULED = 'shipmentScheduled',
}

export interface BaseNotification {
  id: string;
  userId: number;
  createdAt: string;
  markAsReadAt: string | null;
  offline?: boolean;
}

export interface ItemSubmittedContext {
  itemId: number;
}
export interface ItemSubmittedNotification extends BaseNotification {
  type: NotificationTypes.ITEM_SUBMITTED;
  context: ItemSubmittedContext;
}

export interface ShipmentScheduledContext {
  shipmentType: SchedulingType,
}

export interface ShipmentScheduledNotification extends BaseNotification {
  type: NotificationTypes.SHIPMENT_SCHEDULED;
  context: ShipmentScheduledContext;
}

export interface ItemRejectedContext {
  item: Item;
}

export interface ItemRejectedNotification extends BaseNotification {
  type: NotificationTypes.ITEM_REJECTED;
  context: ItemRejectedContext;
}

export interface ItemNotArrivedContext {
  item: Item;
}
export interface ItemNotArrivedNotification extends BaseNotification {
  type: NotificationTypes.ITEM_NOT_ARRIVED;
  context: ItemNotArrivedContext;
}

export type NotificationI =
  | ItemSubmittedNotification
  | ShipmentScheduledNotification
  | ItemRejectedNotification
  | ItemNotArrivedNotification;


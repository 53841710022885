import React, { useEffect, useMemo, useState } from 'react';
import AddressInput from 'src/components/AddressInput/AddressInput.tsx';
import PickupSlots from 'src/components/PickupSlots/PickupSlots.tsx';
import { useAppDispatch, useAppSelector } from 'src/store';
import {
    getSchedulingCurrentStep,
    setStepsLength,
    getSelectedShippingMethod, getSchedulingItems, getSelectedItems,
} from 'src/store/schedulingSlice';
import { SchedulingType } from 'src/constants/item.constants';
import { PopUpContainer } from 'src/components/SchedulingMethods/SchedulingMethods.styles.tsx';
import SchedulingMethods from 'src/components/SchedulingMethods/SchedulingMethods.tsx';
import { getIsAllPending } from 'src/store/itemsSlice';
import { isUltraValueLeads } from 'src/helpers/common.ts';
import { Item } from 'src/types/item.ts';

const SchedulingPopUp: React.FC<{isPC: boolean}> = ({isPC}) => {
    const dispatch = useAppDispatch();

    const currentStep: number = useAppSelector(getSchedulingCurrentStep);
    const selectedShippingMethod: '' | SchedulingType = useAppSelector(getSelectedShippingMethod);
    const allPending = useAppSelector(getIsAllPending);
    const items: Item[] = useAppSelector(getSchedulingItems);
    const selectedScheduled = useAppSelector(getSelectedItems);
    const [ isUltraValue, setIsUltraValue ] = useState(false);

    useEffect(() => {
        const selectedItems = items.filter((item) => selectedScheduled.includes(item.publicId));
        setIsUltraValue(selectedItems.some(item => isUltraValueLeads(item)));
    }, [ selectedScheduled ]);

    const steps = useMemo(() => {
        const commonSteps = [
            { component: <SchedulingMethods isPC={isPC} key="0" />, small: true, allPending: allPending || isUltraValue },
            { component: <AddressInput isPC={isPC} key="1" />, small: true, allPending: false },
        ];

        if (selectedShippingMethod === SchedulingType.PICKUP) {
            commonSteps.push({ component: <PickupSlots isPC={isPC} key="2" />, small: false, allPending: false });
        }

        return commonSteps;
    }, [ selectedShippingMethod, allPending, isUltraValue ]);

    useEffect(() => {
        dispatch(setStepsLength(steps.length));
    }, [ dispatch, steps.length ]);

    return <PopUpContainer isPC={isPC} small={ steps[currentStep].small } allPending={ steps[currentStep].allPending }>{ steps[currentStep].component }</PopUpContainer>;
};

export default SchedulingPopUp;

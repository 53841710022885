import React, { useEffect, useState } from 'react';
import { Box, RadioGroup, Typography } from '@worthy-npm/worthy-common-ui-components';
import {
    DisabledWrapper,
    FloatingDivider,
    InsuranceText,
    ItemCountInShipment,
    ItemsCount,
    ItemsCountWrapper,
    Logos,
    MethodsCtaBtn,
    MethodWrapper,
    PartnersWrapper, ScheduleCallBtn,
    SchedulingMethodsContainer,
    Title,
} from './SchedulingMethods.styles.tsx';
import { Icon } from 'src/components/Icons.tsx';
import { FormControl, RadioBtn } from 'src/styles/RadioBtn/RadioBtn.styles.tsx';
import {
    getMainItemPublicId,
    getSchedulingItems, getSelectedItems,
    getSelectedShippingMethod,
    nextStep,
    setShippingMethod,
} from 'src/store/schedulingSlice';
import { useAppDispatch, useAppSelector } from 'src/store';
import ClickableHelpTooltip from 'src/components/ClickableHelpTooltip/ClickableHelpTooltip.tsx';
import { SchedulingType } from 'src/constants/item.constants.tsx';
import { getMainItemByValueCategory, isUltraValueLeads, sendUserEvent } from 'src/helpers/common.ts';
import { Item } from 'src/types/item.ts';
import SchedulingHeader from 'src/components/SchedulingPopUp/schedulingHeader.tsx';
import Calendly from 'src/helpers/calendly.ts';
import { getUser } from 'src/store/userSlice';
import { getIsAllPending, getItems } from 'src/store/itemsSlice';
import { isItemPreScheduling } from 'src/helpers/scheduling/item.ts';
import { getRoute } from 'src/store/sharedSlice/shared.slice.ts';
import { Icon as CommonIcons } from '../Icons';


const Methods = [
    {
        value: SchedulingType.PICKUP,
        label: 'Schedule FedEx Pickup',
        btnTitle: 'Continue with Fedex Pickup',
        tooltipTxt: 'Select this option if you prefer FedEx to pick up your shipment directly from your specified location.',
    },
    {
        value: SchedulingType.DROPOFF,
        label: 'Drop Off at FedEx',
        btnTitle: 'Continue with Fedex Drop-off',
        tooltipTxt: 'Choose this option to drop off your shipment at a designated FedEx location.',
    },
];

const disabledText = {
    pending: 'You have 1 or more items still pending approval; therefore, shipping options are not yet available. For more information on item status or next steps, schedule a call with our team.',
    ultra: 'It looks like you have an especially valuable item; therefore, our team would like to personally arrange shipping. Please contact us to discuss your preferred shipping option.',
    noItems: 'There are no active items selected for shipping.',
};

const SchedulingMethods: React.FC<{ isPC: boolean }> = ({ isPC }) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(getUser);
    const route = useAppSelector(getRoute);
    const mainItemPublicId = useAppSelector(getMainItemPublicId);
    const allPending = useAppSelector(getIsAllPending) && route.includes('shipping');
    const method: SchedulingType = useAppSelector(getSelectedShippingMethod);
    const itemList = useAppSelector(getItems);
    const items: Item[] = useAppSelector(getSchedulingItems);
    const selectedScheduled = useAppSelector(getSelectedItems);

    const itemsCount = items.length;
    const [ selectedMethod, setSelectedMethod ] = useState<SchedulingType>(method);
    const [ btnTitle, setBtnTitle ] = useState(Methods.find(i => i.value === method)!.btnTitle);
    const [ isUltraValue, setIsUltraValue ] = useState(false);
    const shouldHidePartnersWrapper = isUltraValue || allPending || !selectedScheduled.length;

    useEffect(() => {
        const selectedItems = items.filter((item) => selectedScheduled.includes(item.publicId));
        setIsUltraValue(selectedItems.some(item => isUltraValueLeads(item)));
    }, [ selectedScheduled ]);

    useEffect(() => {
        window.addEventListener('message', handleCalendly);
    }, []);

    const handleCalendly = (event: MessageEvent) => {
        if (Calendly.isCalendlyEvent(event)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            if (event.data.event.indexOf('event_scheduled') > -1) {
                console.info('Calendly event_scheduled', event.data);
            }
        }
    };

    const scheduleCall = () => {
        // valid and pending items
        const schedulingItems = itemList.filter((item) => isItemPreScheduling(item.status));

        const watch = schedulingItems.find((item) => item.itemType?.toLowerCase().includes('watch'));
        const callableItem = watch ? watch : getMainItemByValueCategory(schedulingItems);
        const link = Calendly.getCalendlyLinkByValueCategory(callableItem);

        Calendly.showPopupWidget(link, Calendly.calendlyParams(callableItem, user));
    };

    const onNextClick = () => {
        dispatch(setShippingMethod(selectedMethod));
        dispatch(nextStep());
    };

    const getRadioBtn = (value: string, label: string, tooltipTxt: string) => (
        <MethodWrapper key={ value }>
            <FormControl
                key={ `form-control-${ value }` }
                data-automation={ `radio-${ value }` }
                value={ value }
                control={ <RadioBtn /> }
                label={ label }
                disabled={ !mainItemPublicId || shouldHidePartnersWrapper }
            />
            <ClickableHelpTooltip
                key={ `tooltip-${ value }` }
                title={ tooltipTxt }
                large={ true }
            />
        </MethodWrapper>
    );

    const getPartnersLogos = () => (
        <Logos>
            <Icon.FedexIcon
                color="secondary"
                fontSize="large"
                sx={ {
                    width: '65px',
                    height: '20px',
                } }
            />
            <Icon.LloydsIcon
                color="secondary"
                fontSize="large"
                sx={ {
                    width: '63px',
                    height: '13px',
                } }
            />
            <Icon.GiaIcon
                color="secondary"
                fontSize="large"
                sx={ {
                    width: '65px',
                    height: '29px',
                } }
            />
        </Logos>
    );

    const getItemsCount = () => (
        <ItemsCountWrapper>
            <ItemsCount>
                { itemsCount } { itemsCount === 1 ? 'item' : 'items' }
            </ItemsCount>
        </ItemsCountWrapper>
    );

    const handleMethodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value as SchedulingType;
        sendUserEvent(`${ value } radio button selected`, mainItemPublicId);
        setSelectedMethod(value);
        setBtnTitle(Methods.find((method) => method.value === value)!.btnTitle);
    };

    const getSchedulingMethods = () => (
        <RadioGroup
            aria-labelledby="scheduling-popup-radio-buttons-group-label"
            value={ selectedMethod }
            name="scheduling-popup-radio-buttons-group"
            sx={ {
                alignSelf: 'stretch',
            } }
            onChange={ (e) => handleMethodChange(e) }
        >
            { Methods.map((method) => getRadioBtn(method.value, method.label, method.tooltipTxt)) }
        </RadioGroup>
    );

    const getDisabledText = () => {
        if (isUltraValue) {
            return disabledText.ultra;
        }
        if (allPending) {
            return disabledText.pending;
        }
        return disabledText.noItems;
    };

    return (
        <>
            <SchedulingHeader isPC={ isPC } title="Shipping options" firstStep={ true } />
            <Title align="left" disabled={!mainItemPublicId || shouldHidePartnersWrapper} >How would you like to send your item?</Title>

            <SchedulingMethodsContainer empty={ shouldHidePartnersWrapper }>
                { getSchedulingMethods() }

                { !shouldHidePartnersWrapper && (
                    <>
                        <PartnersWrapper>
                            <FloatingDivider flexItem />
                            <InsuranceText>**Insurance and fees covered</InsuranceText>
                            { getPartnersLogos() }
                        </PartnersWrapper>
                        <ItemCountInShipment>
                            <InsuranceText>Items in this shipment</InsuranceText>
                            { getItemsCount() }
                        </ItemCountInShipment>
                    </>
                ) }

                <MethodsCtaBtn
                    variant="contained"
                    onClick={ onNextClick }
                    disableElevation
                    disabled={ !mainItemPublicId || shouldHidePartnersWrapper }
                    data-automation="shipping-method-btn"
                >
                    { btnTitle }
                </MethodsCtaBtn>

                {
                    shouldHidePartnersWrapper && (
                        <DisabledWrapper>
                            <Box>
                                <Typography
                                    variant="caption"
                                    fontWeight="600"
                                >
                                    { isUltraValue || allPending ? 'Why Can\'t I Ship My Item?' : 'Select Items for Shipment' }
                                </Typography>
                                <Typography >
                                    { getDisabledText() }
                                </Typography>
                            </Box>
                            <ScheduleCallBtn
                                disableElevation
                                variant="contained"
                                onClick={ scheduleCall }
                                data-automation="shipping-method-btn"
                            >
                                <CommonIcons.PhoneIcon sx={ { width: '18px', height: '18px', marginRight: '8px' } } />
                                Schedule Call
                            </ScheduleCallBtn>
                        </DisabledWrapper>


                    )
                }
            </SchedulingMethodsContainer>
        </>
    );
};

export default SchedulingMethods;

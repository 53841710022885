import { Icon, Stack, styled } from "@worthy-npm/worthy-common-ui-components";
import _ from "lodash";
import { ItemTypes } from "src/constants/item.constants";

interface ItemTypePlaceHolderProps {
    itemType: string;
}

const ItemTypePlaceHolderContainer = styled(Stack)(({theme})=>({
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.secondary.main,
    padding: theme.spacing(2),
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
}));
const ItemTypePlaceHolder = ({itemType}:ItemTypePlaceHolderProps) => {
    const itemTypeIcon = ItemTypes
        .filter((type) => type.type?.toLowerCase().includes(_.toLower(itemType)))[0]?.icon || <Icon.CrossedRings />;
    return (
        <ItemTypePlaceHolderContainer >
            {itemTypeIcon}
        </ItemTypePlaceHolderContainer>
    );
}

export default ItemTypePlaceHolder;
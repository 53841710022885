import React from 'react';
import { CtaButton, DescriptionText, StyledStack } from './StepsActions.styles';
import { Item } from 'src/types/item.ts';
import { printLabel } from './PickupStep';
import { CircularProgress } from '@worthy-npm/worthy-common-ui-components';

interface DropOffStepProps {
    item: Item;
    onActionShow?: () => void;
}

const DropOffStep: React.FC<DropOffStepProps> = ({ item , onActionShow}) => {
    const [isClicked, setIsClicked] = React.useState(false);
    const onclick = () => {
        onActionShow?.();
        setIsClicked(true);
    };
    const isLabelReady = item?.schedulingDetails?.shipment?.shippingLabelUrl;
    return (
        <StyledStack>
            <DescriptionText>
                Nearest FedEx: <br /> {item?.schedulingDetails?.nearestFedExLocations?.[0]}
            </DescriptionText>
            {isClicked ? (<CtaButton
                    onClick={ () => {
                        printLabel(item).catch(err => console.error(err));
                    } }
                    disabled={ !isLabelReady }
                >
                    { 'Print Shipping Labels' } {!isLabelReady && <CircularProgress size={20} color="inherit" sx={{ml: 1}} />}
                </CtaButton>) :
                <CtaButton onClick={onclick}>Prepare for drop-off</CtaButton>
            }
        </StyledStack>
    );
};

export default DropOffStep;

import {
    AccordionDetails,
    Box,
    Link,
    Stack,
    Step,
    StepLabel,
    Typography,
} from '@worthy-npm/worthy-common-ui-components';
import {
    AccordionSummaryWrapper,
    ColorlibConnector,
    ContentStepWrapper,
    NumberWrapper,
    Process,
    StepperWrapper,
    StepWrapper,
    StyledAccordion,
    StyledArrowIcon,
    StyledExpandMoreIcon,
} from './AccordionStepper.styles';
import ArrowIcon from '@worthy-npm/worthy-common-ui-components/components/Icon/Arrow';
import { StepIconProps } from '@worthy-npm/worthy-common-ui-components/components/Stepper/StepIcon';
import { Fragment, useState } from 'react';

export interface HIWProps {
    steps: { title: string; description: string, img: string }[];
    title: string;
    moreInfoLink?: string;
    previewTitle?: string;
    previewText?: string;
    currentStepIndex: number;
}

function Star({ active, completed }: StepIconProps) {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 1C8.80646 4.75556 11.7461 7.68565 15.5043 8.47986L15.5996 8.5L15.5043 8.52014C11.7461 9.31435 8.80646 12.2444 8 16L7.97956 15.9048C7.18461 12.2028 4.29889 9.3074 0.599609 8.5C4.29889 7.6926 7.18461 4.79716 7.97956 1.09518L8 1Z"
                fill={ active ? '#73A0F7' : completed ? '#7B8083' : '#F8FAFF' }
                stroke={ completed ? '#7B8083' : '#73A0F7' }
                strokeWidth="2"
            />
        </svg>
    );
}

const AccordionStepper = ({ title, steps, currentStepIndex, moreInfoLink }: HIWProps) => {
    const [ showPreview, setShowPreview ] = useState(true);

    return (
        <>
            <Typography
                variant="h2"
                textAlign={ 'center' }
                mb={{md: 3 }}
            >
                { title }
            </Typography>
            <NumberWrapper
                id="number-wrapper"
            >
                { steps.map((step, index) => (
                    <Fragment key={ index }>
                        <Process>
                            <StepWrapper
                                index={ index }
                                currentStepIndex={ currentStepIndex }
                            >
                                <Typography variant="h4">{ index + 1 }</Typography>
                                <Typography
                                    variant="caption"
                                    fontWeight="350"
                                    textAlign="center"
                                    fontSize="0.75rem"
                                >
                                    { step.title }
                                </Typography>
                            </StepWrapper>
                            { index !== steps.length - 1 && (
                                <Box pt={ 0.5 }>
                                    <StyledArrowIcon doneStep={ index < currentStepIndex } />
                                </Box>
                            ) }
                        </Process>
                    </Fragment>
                )) }
            </NumberWrapper>
            <Stack direction="column">
                <StyledAccordion
                    color="primary"
                    onChange={ () => setShowPreview(!showPreview) }
                >
                    <AccordionSummaryWrapper
                        data-automation="hiw-expand-accordion"
                        expandIcon={ <StyledExpandMoreIcon fontSize='inherit' /> }
                        showPreview={ showPreview }
                    >
                        <Typography
                            variant="caption">{ showPreview ? 'More Information' : 'Close' }</Typography>
                    </AccordionSummaryWrapper>
                    <AccordionDetails>
                        <Box>
                            <StepperWrapper
                                orientation="vertical"
                                connector={ <ColorlibConnector /> }
                            >
                                { steps.map((item, index) => (
                                    <Step
                                        key={ index }
                                        active={ true }
                                    >
                                        <StepLabel
                                            StepIconProps={ {
                                                active: index === currentStepIndex,
                                                completed: index < currentStepIndex,
                                            } }
                                            StepIconComponent={ Star }
                                        >
                                            <Typography
                                                variant="caption"
                                                color="text.primary"
                                                fontWeight={ index === currentStepIndex ? 700 : 500 }
                                            >
                                                { item.title }
                                            </Typography>
                                        </StepLabel>
                                        <ContentStepWrapper item={ index }>
                                            <Typography
                                                variant="caption"
                                                color="text.primary"
                                            >
                                                { item.description }
                                            </Typography>
                                        </ContentStepWrapper>
                                    </Step>
                                )) }
                            </StepperWrapper>

                            { moreInfoLink && (
                                <Box
                                    sx={ {
                                        color: 'primary.main',
                                        display: 'flex',
                                        gap: '11px',
                                        fontSize: '15px',
                                        fontWeight: 500,
                                        justifyContent: 'center',
                                    } }
                                >
                                    <Link
                                        href={ moreInfoLink }
                                        sx={ { textDecoration: 'none' } }
                                    >
                                        more information
                                    </Link>
                                    <ArrowIcon />
                                </Box>
                            ) }
                        </Box>
                    </AccordionDetails>
                </StyledAccordion>
            </Stack>
        </>
    );
};
export default AccordionStepper;
